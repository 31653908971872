<template>
  <div>
  <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <!-- Indicators -->
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>

    <!-- Wrapper for slides -->
    <div class="carousel-inner">
     <div class="item active">
         <img
            src="@/assets/thingsToDo/Thingstodo-011.jpg"
            width="1150"
            height="400"
         
          />
           <img
            src="@/assets/thingsToDo/arrow.png"
            width="40"
            height="40"
            style="    position: absolute;
    bottom: 4%;
    right: 1%;
    width: 13%;
    height: auto;"
          />
      </div>
     
     
    
      <div class="item">
        <img
            src="@/assets/thingsToDo/Thingstodo-0333.png"
            width="1150"
            height="400"
            style="position:relative"
          />
            <img
            src="@/assets/thingsToDo/createyourteam11.png"
            width="90"
            height="40"
            style="    position: absolute;
    bottom: 18%;
    right: 26%;
    width: 41%;
    height: auto;cursor: pointer;"
         @click="toggleCreateTeamModal({ show: true, type: 'add' })" />
           <img
            src="@/assets/thingsToDo/arrow.png"
            width="40"
            height="40"
            style="    position: absolute;
    bottom: 4%;
    right: 1%;
    width: 13%;
    height: auto;"
          />
      </div>
       <div class="item">
       <img
            src="@/assets/thingsToDo/Thingstodo-044.jpg"
            width="1150"
            height="400"
            style="position:relative"
          />
            <img
            src="@/assets/thingsToDo/start_watching_now.png"
            width="90"
            height="40"
            style="    position: absolute;
    bottom: 30%;
    right: 35%;
    width: 20%;
    height: auto;cursor: pointer;"
              @click="$vuetify.goTo('#videoSection')"/>
           <img
            src="@/assets/thingsToDo/arrow.png"
            width="40"
            height="40"
            style="    position: absolute;
    bottom: 4%;
    right: 1%;
    width: 13%;
    height: auto;"
          />
          </div>
           <div class="item ">
         <img
            src="@/assets/thingsToDo/Thingstodo-022.jpg"
            width="1150"
            height="400"
            style="position:relative"
          />
            <img
            src="@/assets/thingsToDo/downloadshuffle.png"
            width="90"
            height="40"
            style="    position: absolute;
    bottom: 29%;
    right: 37%;
    width: 12%;
    height: auto;"
          />
            <img
            src="@/assets/thingsToDo/wizfitshuffle.png"
            width="90"
            height="40"
            style="position: absolute;
    bottom: 75%;
    right: 7%;
    width: 26%;
    height: 61px;cursor: pointer;"
         @click="getSong()" />
            <img
            src="@/assets/thingsToDo/wizfitshuffleremix.png"
            width="90"
            height="40"
            style="    position: absolute;
    bottom: 58%;
    right: 5%;
    width: 30%;
    height: 67px;cursor: pointer;"
           @click="wizfitshuffleremix()"/>
            <img
            src="@/assets/thingsToDo/wizfitshuffleremixtutorial.png"
            width="90"
            height="40"
            style="    position: absolute;
    bottom: 36%;
    right: 3%;
    width: 33%;
    height: 81px;cursor: pointer;"
           @click="wizfitshuffleremixtutorial()"/>
            <img
            src="@/assets/thingsToDo/download_it.png"
            width="90"
            height="40"
            style="    position: absolute;
    bottom: 34%;
    right: 79%;
    width: 16%;
    height: auto;"
       />
          <img
            src="@/assets/thingsToDo/practice_it.png"
            width="90"
            height="40"
            style="    position: absolute;
    bottom: 24%;
    right: 79%;
    width: 16%;
    height: auto;"
          />
          <img
            src="@/assets/thingsToDo/share_it.png"
            width="90"
            height="40"
            style="    position: absolute;
    bottom: 15%;
    right: 67%;
    width: 28%;
    height: auto;"
          />
           <img
            src="@/assets/thingsToDo/wizfit4.png"
            width="90"
            height="40"
            style="    position: absolute;
    bottom: 7%;
    right: 42%;
    width: 53%;
    height: auto;"
          />
      </div>

    </div>

    <!-- Left and right controls -->
    <a class="left carousel-control" href="#myCarousel" data-slide="prev" >
      <span class="glyphicon glyphicon-chevron-left"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" data-slide="next" >
      <span class="glyphicon glyphicon-chevron-right"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <create-team-modal ></create-team-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { GET_WIZFIT_SONG } from "@/constants/APIUrls";
// import axios from "axios";
import Axios from "@/api/BaseAxios";
export default {
  name: "ThingsToDo",
   components: {
     CreateTeamModal: () => import("./CreateTeamModal/CreateTeamModal.vue"),
  },
  data() {
    return {
      donations: null,
      invites: null,
      videoWatched: null,
      totalVideo: 6,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    studentDetail() {
      return this.getCampaign.student_detail;
    },
      layout() {
      return this.$route.meta.layout;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleInviteViaMailModal: "publicDonationPage/toggleInviteMail",
      toggleCreateTeamModal: "publicDonationPage/toggleInviteMail",
    }),
  
    getSong() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res);
        const blob = new Blob([res.data], {
          type: "audio/mp3",
        });
        const download_url = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = download_url;

        link.setAttribute("download", "WizfitSong.mp3"); //any other extension

        document.body.appendChild(link);

        link.click();
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      Axios.request_GET_BLOB(
        GET_WIZFIT_SONG,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );

      // axios
      //   .get(GET_WIZFIT_SONG, {
      //     responseType: "arraybuffer",
      //     headers: {
      //       "Content-Type": "audio/mp3",
      //     },
      //   })
      //   .then((res) => {
      //     const blob = new Blob([res.data], {
      //       type: "audio/mp3",
      //     });
      //     const download_url = URL.createObjectURL(blob);
      //     const link = document.createElement("a");

      //     link.href = download_url;

      //     link.setAttribute("download", "file.mp3"); //any other extension

      //     document.body.appendChild(link);

      //     link.click();
      //   }).catch(res=>{
      //     console.log(res)
      //   })
 
    },
     downloadFile () {
                const url = 'users/download';
                window.location.href = url;
            },
      wizfitshuffleremixtutorial () {
                const url = 'https://harlemwizardsinabox-prod-bucket.s3.amazonaws.com/media/campaign/campaign_assets/Harlem_Wizards_Tutorials.mp4';
                window.location.href = url;
            },
             startWatching () {
                const url = 'https://harlemwizardsinabox-prod-bucket.s3.amazonaws.com/media/wizfit_video/video/ZEROHATE_2.0.mp4';
                window.location.href = url;
            },
             wizfitshuffleremix () {
                const url = 'https://music.apple.com/us/album/wiz-fit-shuffle-feat-matt-sanchez-single/1591955454';
                window.location.href = url;
            },
            
  },
  mounted() {
    this.donations = this.studentDetail.total_donor;
    this.invites = this.studentDetail.total_invites;
    this.videoWatched = this.studentDetail.videos_watched;
  },
};
</script>
<style scoped>
.heading {
  font-size: 20px;
  font-family: Roboto Slab;
  font-weight: 500;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 30px;
  height: 2px;
  border-radius: 30px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.imgThing {
  position: relative;
  bottom: 290px;
  float: right;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 15px;
  }
  .hLine {
    background-color: #2c1963;
    width: 20px;
    height: 2px;
    margin-top: 15px;
  }
  .imgThing {
    position: static;
  }
   .parent {
        position: relative;
        width: 500px;
      }
      .image1 {
        position: relative;
        width:100%;
        border: 1px solid #000000;
      }
      .image2 {
       position: absolute;
    bottom: 10%;
    right: 10%;
    width: 25%;
    height: auto;
      }
      
}
</style>
